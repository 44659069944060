exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-js": () => import("./../../../src/pages/auth.js" /* webpackChunkName: "component---src-pages-auth-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-component-library-js": () => import("./../../../src/pages/component-library.js" /* webpackChunkName: "component---src-pages-component-library-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-email-confirm-js": () => import("./../../../src/pages/email-confirm.js" /* webpackChunkName: "component---src-pages-email-confirm-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */)
}

